<template>
  <div>
    <!-- basic modal -->
    <b-modal id="modal-1" :title="'Indikator Sasaran : ' + rsDetail.indikator_sasaran" ok-disabled no-close-on-backdrop
      hide-footer size="lg">
      <b-card-text>
        <b-form-group>
          <label>Tambah Program Kerja</label>
          <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_program"
            @input="simpanProgramIndikator(rsDetail.id, selected.id_program)" :options="kodeprogram"
            placeholder="Silahkan pilih salah satu program kerja.." />
        </b-form-group>
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal, vSelect,
    BAlert,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected: {}
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["simpanProgramIndikator"],
  methods: {
    simpanProgramIndikator(id, id_program) {
      this.$emit('simpanProgramIndikator', id, id_program)
      this.close();
      this.selected = {}
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    }
  },
  props: {
    rsDetail: {

    },
    kodeprogram: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>