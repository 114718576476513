<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <tabel-data :rs_data=rs_data @DetailIndikator=DetailIndikator @DeleteProgram="DeleteProgram"></tabel-data>
        </b-card>
      </b-col>
    </b-row>
    <tambah-program-kerja :rsDetail=rsDetail :kodeprogram=kodeprogram @simpanProgramIndikator="simpanProgramIndikator" />
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BRow, BCol, BCard
} from 'bootstrap-vue'
import TabelData from './coomponent/tabel_data.vue'
import TambahProgramKerja from './coomponent/TambahProgramKerja.vue'
export default {
  components: {
    BTabs,
    BTab, BRow, BCol, BCard,
    TabelData,
    TambahProgramKerja
  },
  props: {

  },
  data() {
    return {
      rs_data: [],
      kodeprogram: [],
      rsDetail: {}
    }
  },
  mixins: [Base],
  mounted() {
    this.load_data();
    this.kode_program();
  },
  methods: {
    DetailIndikator(val) {
      this.rsDetail = val
    },
    async load_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/indikator/load_data',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_data = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async kode_program() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/indikator/kode_program',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.kodeprogram = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async simpanProgramIndikator(id, id_program) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/setup/indikator/insert',
        data: {
          id_indikator: id,
          id_program: id_program
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "", response.data.message);
          self.load_data()

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async DeleteProgram(id) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/setup/indikator/delete_program',
        data: {
          id: id
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "", response.data.message);
          self.load_data()

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>